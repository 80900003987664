import { render, staticRenderFns } from "./BannerTwoSection.vue?vue&type=template&id=f4493b32&"
import script from "./BannerTwoSection.vue?vue&type=script&lang=js&"
export * from "./BannerTwoSection.vue?vue&type=script&lang=js&"
import style0 from "./BannerTwoSection.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports