<template>
  <div class="privacy">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Polityka prywatności i wykorzystania plików cookies</h2>
        <svg @click="$emit('close')" width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="#005CE6"/>
        </svg>
      </div>
      <div class="modal-body">
        <div>
          <b>Ochrona prywatności</b><br>
          <p>Główny Urząd Nadzoru Budowlanego (zwany dalej GUNB) przywiązuje szczególną wagę do poszanowania prywatności
            użytkowników odwiedzających Serwisy administrowane przez GUNB (zwane dalej Serwisami). Gromadzone w
            dziennikach logów dane są wykorzystywane wyłącznie do celów administrowania serwisem oraz analizy jego
            funkcjonowania w celu poprawy jakości usług.</p>
          <br>
          <b>Administrowanie Serwisami</b><br>
          <p>Czynności związane z funkcjonowaniem Serwisów podejmuje poprzez swoje komórki organizacyjne: Departament
            Usług Cyfrowych, Wydział Informatyki: ul. Krucza 38/42, 00-926 Warszawa.</p>
          <br>
          <b>Gromadzenie danych</b><br>

          <p>Wszelkie dane pochodzące od użytkowników Serwisów gromadzone są na dwa sposoby:</p>
          <ul>
            <li>informacje podawane dobrowolnie przez użytkownika – o podanie danych osobowych prosimy użytkowników
              korzystających z formularzy rejestracyjnych i kontaktowych,
            </li>

            <li>informacje uzyskiwane podczas korzystania z Serwisów – wśród nich mogą być:</li>
            <ul>
              <li>informacje w dziennikach serwerów – nasze serwery automatycznie zapisują takie dane, jak żądanie
                strony wysyłane przez użytkownika, datę i godzinę żądania oraz wysłania odpowiedzi, dane urządzenia (np.
                model sprzętu), typ przeglądarki, język przeglądarki, typ systemu operacyjnego,
              </li>
              <li>informacje pobierane przez narzędzia Google Analytics, Mixpanel i Microsoft Clarity w procesie
                monitorowania statystyk odwiedzin strony internetowej,
              </li>
              <li>adres IP – każdy komputer podłączony do Internetu ma przypisany niepowtarzalny numer, czyli adres IP;
                na jego podstawie można np. zidentyfikować kraj, z którego dany użytkownik łączy się z siecią,
              </li>
              <li>pliki tekstowe (ang. "cookies") wysyłane do komputera użytkownika podczas odwiedzania strony
                internetowej.
              </li>
            </ul>
          </ul>

          <p>Administratorem danych zawartych w Serwisach jest Główny Inspektor Nadzoru Budowlanego.</p>
          <br>
          <b>Dane osobowe</b><br>
          <p>W czasie korzystania z Serwisu może Pan/Pani zostać poproszony o podanie niektórych danych osobowych m.in.
            poprzez wypełnienie formularzy. W każdym takim przypadku zostanie Pani/Pan poinformowany o: administratorze
            Pani/Pana danych osobowych, celu zbierania danych oraz przysługujących Pani/Panu prawach.
            Zawsze wymagamy podania tylko tych danych, które są niezbędne do działania Serwisu. Niepodanie wymaganych
            danych zablokuje czynność, której one dotyczyły.</p>

          <p>Inspektorem Ochrony Danych w Głównym Urzędzie Nadzoru Budowlanego jest Pani Agnieszka Rzycka-Osiej, e-mail:
            iod@gunb.gov.pl.</p>

          <p>Dodatkowe informacje dotyczące przetwarzanych w GUNB danych osobowych znajdzie Pani/Pan pod adresem:<br>
            https://www.gunb.gov.pl/strona/informacja-o-przetwarzaniu-danych-osobowych-w-serwisie</p>
          <br>
          <b>Mechanizm cookies</b><br>
          <p>Pliki cookies (ciasteczka) to niewielkie pliki tekstowe, które wysyła odwiedzany przez Pani/Pana serwis
            internetowy do Pani/Pana urządzenia (komputer, smartfon itp.), które rozpoznają urządzenie użytkownika i
            odpowiednio wyświetlają stronę internetową, dostosowaną do jego indywidualnych potrzeb, aby ułatwić
            użytkownikom korzystanie z witryny i są używane by:</p>
          <ul>
            <li><p>tworzyć anonimowe statystyki serwisu dzięki temu lepiej poznajemy oczekiwania naszych użytkowników i
              rozwijamy serwis tak, aby ułatwić dotarcie do najczęściej poszukiwanych informacji (np. analizując
              zapytania z wyszukiwarek),</p></li>
            <li><p>prezentować treści multimedialne na stronach internetowych serwisu, które są pobierane z zewnętrznego
              serwisu internetowego,</p></li>
            <li><p>zapewniać poprawne działanie Serwisu, w tym procesy logowania i autoryzacji użytkowników,</p></li>
            <li><p>pliki cookies nie łączą się z żadnymi innymi danymi, a zwłaszcza z danymi osobowymi, a tym samym ma
              Pani/Pan pewność o anonimowości.</p></li>
          </ul>
          <p>Pliki cookies <b>Google Analytics, Mixpanel i Microsoft Clarity</b> – to anonimowe statystyki dla naszego
            serwisu, dzięki którym możemy lepiej poznać Twoje oczekiwania i rozwijać serwis tak, by był jeszcze bardziej
            przyjazny.</p><br>
          <p>W celu ochrony prywatności użytkowników korzystamy z anonimizacji adresów IP w Google Analytics. Możesz
            również w każdej chwili zrezygnować ze śledzenia przez Google Analytics oraz przez Mixpanel zmieniając
            ustawienia na „tylko niezbędne” po przewinięciu tej strony na sam dół. Zmieniając ustawienia na „Tylko
            niezbędne”, wyłączasz wszystkie pliki cookies analityczne i marketingowe, pozostawiając jedynie te, które są
            niezbędne do funkcjonowania serwisu.”</p>

          <p>Niektóre dane analityczne mogą być przetwarzane przez <b>Google, Mixpanel i Microsoft na serwerach poza
            Europejskim Obszarem Gospodarczym (EOG)</b>, w tym w Stanach Zjednoczonych. W takich przypadkach stosowane
            są <b>Standardowe Klauzule Umowne (SCC)</b> zatwierdzone przez Komisję Europejską w celu zapewnienia
            odpowiedniego poziomu ochrony danych.</p>

          <p>Pliki cookies mogą być przechowywane przez różny okres:</p>
          <ul>
            <li><b>Cookies sesyjne</b> – usuwane po zamknięciu przeglądarki.</li>
            <li><b>Cookies trwałe</b> – przechowywane na urządzeniu użytkownika do 12 miesięcy lub do momentu ich
              usunięcia.
            </li>
          </ul>
          <br>
          <b>Wykorzystywanie danych</b><br>
          <p>Dane zbierane w ramach naszych Serwisów służą do zapewnienia określonych usług naszym użytkownikom oraz do
            celów administracyjnych i statystycznych, a także do ochrony Serwisów.</p>
          <p>Dostęp do danych posiada także firma Google Inc. (poprzez wykorzystywanie do celów statystycznych
            funkcjonalności Google Analytics), Mixpanel Inc. oraz Microsoft Corp. (poprzez narzędzie Microsoft Clarity)
            oraz serwisy społecznościowe takie jak: Facebook i Twitter (poprzez obecność na stronach wtyczek
            umożliwiających dzielenie się treścią).</p>
          <p>Dane te są przetwarzane zgodnie z politykami prywatności dostawców tych usług, które można znaleźć pod
            następującymi adresami: Google Analytics: https://policies.google.com/privacy Mixpanel:
            https://mixpanel.com/legal/privacy-policy/ Microsoft Clarity: https://privacy.microsoft.com</p>
          <p>W ramach Serwisów mogą znajdować się przyciski, narzędzia lub treści kierujące do usług innych firm, m.in.:
            wtyczki portali społecznościowych (Facebook, Twitter, Instagram czy Google+), mapy (Google Maps), filmy
            (Youtube). Korzystanie z tych aplikacji może powodować przesyłanie informacji o użytkownikach do ww.
            zewnętrznych podmiotów.</p>
          <p>Na podstawie informacji uzyskanych w ramach Serwisów, GUNB może sporządzać zbiorcze zestawienia
            statystyczne, które mogą być udostępniane osobom trzecim. Zestawienia takie nie zawierają jednak żadnych
            danych pozwalających na identyfikację pojedynczych użytkowników.</p>
          <p>GUNB zapewnia użytkownikom realizację uprawnień wynikających z przepisów Rozdziału III Rozporządzenia
            Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
            związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia
            dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz. Urz. UE L 119 z 04.05.2016), w
            szczególności prawo wglądu do własnych danych, prawo odwołania w każdym czasie zgody na przetwarzanie danych
            osobowych ich dotyczących, prawo żądania aktualizacji danych oraz prawo wniesienia sprzeciwu wobec
            przetwarzania ich danych w przypadkach określonych w przepisach tej ustawy.</p>
          <br>
          <b>Bezpieczeństwo</b><br>
          <p>Zdajemy sobie sprawę, że ponosimy odpowiedzialność za ochronę danych powierzonych nam przez użytkowników.
            Aby skutecznie chronić zbierane dane zabezpieczamy je przed nieuprawnionym dostępem osób trzecich oraz
            kontrolujemy nasze metody gromadzenia, przechowywania i przetwarzania informacji. Stosujemy między innymi
            firewall, urządzenia zabezpieczające serwer i fizyczne środki bezpieczeństwa.Dostępu do danych udzielamy
            jedynie tym pracownikom i podmiotom, które muszą mieć do nich dostęp, aby je przetwarzać wyłącznie w celach
            opisanych w polityce prywatności.</p>
          <br>
          <p>Serwisy jednak zawierają odnośniki do innych stron internetowych. Rekomendujemy, aby po przejściu na inne
            witryny, zapoznać się z obowiązującą tam polityką prywatności.</p>
          <br>
          <b>Zmiany w polityce prywatności</b><br>
          <p> W przypadku zmiany polityki prywatności obowiązującej w Serwisach, zamieścimy odpowiednią informację na
            tej podstronie.</p>
          <br>
          <hr>
        <p><b>Twoje ustawienia plików cookies:</b></p>
          <br>
        <ul class="modal-content-cookies">
          <li>
            <div><p><b>Niezbędne</b> – wymagane do prawidłowego działania serwisu.</p></div><div><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-checkbox"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 11l3 3l8 -8" /><path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" /></svg></div>
          </li>
          <li>
            <div><p><b>Analityczne</b> – pomagają nam analizować ruch na stronie i ulepszać jej działanie.</p></div><div v-if="this.$cookies.get('cookie-accept-status') == 'All'"><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-checkbox"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 11l3 3l8 -8" /><path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" /></svg></div>
          </li>
        </ul>
        <div class="modal-footer">
          <button type="button" class="button--blue" @click="$emit('close')" data-dismiss="modal">Zamknij</button>
          <button type="button" class="button--white" @click="setEssential" data-dismiss="modal">Akceptuj tylko niezbędne</button>
          <button type="button" class="button--blue" @click="setAll" data-dismiss="modal">Akceptuj wszystkie</button>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script setup lang="ts">
export default {
  name: 'privacy',
  props: {},
  methods: {
    setAll () {
      this.$emit('close')
      this.$cookies.set('cookie-accept-status', 'All')
    },
    setEssential () {
      this.$emit('close')
      this.$cookies.set('cookie-accept-status', 'Essential')
    }
  }
}
</script>
<style>
privacy {
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    outline: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }

  .modal-title {
    font-size: 24px;
    font-weight: 700;
  }

  .modal-body {
    padding: 24px;

    ol, ul {
      list-style: disc;
      margin: 0;
      padding: revert;
    }

    .modal-body p {
      margin-bottom: 16px;
    }

    .modal-body ul {
      margin-bottom: 16px;
    }

    .modal-body ul li {
      margin-bottom: 8px;
    }
  }
}
</style>
