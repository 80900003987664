<template>
   <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Deklaracja dostępności</h2>
        <svg @click="$emit('close')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#005CE6"/>
        </svg>
      </div>
      <div class="modal-body">
         <div>
           <p>Główny Urząd Nadzoru Budowlanego zapewnia dostępność swojej strony internetowej zgodnie z przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do strony internetowej <a href="https://e-crub.gunb.gov.pl/">https://e-crub.gunb.gov.pl/</a></p>
           <br>
            <p><strong>Data publikacji strony internetowej:</strong> 1.01.2021 r.</p>
            <p><strong>Data ostatniej dużej aktualizacji:</strong> 1.10.2024 r.</p>
           <br>
          <p>Strona internetowa jest zgodna z ustawą o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.</p>
           <p>Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny.</p>
           <br>
           <p><strong>Niniejsze oświadczenie sporządzono dnia:</strong> 25.04.2025 r.</p>
           <p><strong>Oświadczenie zostało ostatnio poddane przeglądowi w dniu:</strong> 25.04.2025 r.</p>
           <br>
           <h2>Skróty klawiaturowe</h2>
           <br>
           <p>W części publicznej serwisu, dostępnej bez logowania, można korzystać ze standardowych skrótów klawiaturowych. Dodatkowe skróty klawiszowe, ułatwiające nawigację pomiędzy sekcjami portalu oraz pomiędzy treściami w poszczególnych sekcjach.</p>
           <br>
           <h2>Informacje zwrotne i dane kontaktowe</h2>
           <br>
            <p><strong>Dane teleadresowe siedziby Głównego Urzędu Nadzoru Budowlanego:</strong><br>

             ul. Krucza 38/42<br>

             00-926 Warszawa<br>

             Tel. 22 295 51 28<br>

             Faks 22 661 81 42</p>
           <br>
           <p>Informacje zamieszczone na stronie internetowej są na bieżąco aktualizowane przez Redaktorów. Staramy się zapewnić dostępność strony internetowej, jednak jeżeli znajdziesz błędy lub masz uwagi prosimy o kontakt pod adres e-mail: <a href="mailto:koordynator.dostepnosci@gunb.gov.pl">koordynator.dostepnosci@gunb.gov.pl</a></p>
           <p>W przypadku problemów z dostępnością strony internetowej prosimy o kontakt. Osobą kontaktową jest Łukasz Łobodziec, adres poczty elektronicznej:  <a href="mailto: koordynator.dostepnosci@gunb.gov.pl">koordynator.dostepnosci@gunb.gov.pl</a>. Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać żądania zapewnienia dostępności.</p>
           <p>Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji za pomocą alternatywnego sposobu dostępu, np. przez odczytanie niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez audiodeskrypcji itp.</p>
           <br>
           <p><strong>Żądanie powinno zawierać:</strong></p>

           <ul class="ml-5 list-disc py-3">

             <li>dane osoby zgłaszającej żądanie,</li>

             <li>wskazanie, o którą stronę internetową lub aplikację mobilną chodzi,</li>

             <li>sposób kontaktu.</li>

           </ul>
           <br>
           <p>Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna także określić dogodny dla niej sposób przedstawienia tej informacji.</p>
           <p>Główny Urząd Nadzoru Budowlanego zrealizuje żądanie niezwłocznie, nie później niż w ciągu 7 dni od dnia wystąpienia z żądaniem. Jeżeli dotrzymanie tego terminu nie jest możliwe, niezwłocznie poinformuje o tym wnoszącego żądanie oraz poinformuje o terminie realizacji żądania, przy czym termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem.</p>
           <p>Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, Główny Urząd Nadzoru Budowlanego zaproponuje alternatywny sposób dostępu do informacji. W przypadku, gdy Główny Urząd Nadzoru Budowlanego odmówi realizacji żądania zapewnienia dostępności lub alternatywnego sposobu dostępu do informacji, wnoszący żądanie możne złożyć skargę w sprawie zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub elementu strony internetowej, lub aplikacji mobilnej.</p>
           <br>
           <p>Po wyczerpaniu wszystkich możliwości skargę można przesłać także do Rzecznika Praw Obywatelskich.</p>
           <br>
           <h2>Dostępność architektoniczna budynku Głównego Urzędu Nadzoru Budowlanego</h2>
           <br>
           <p>Szczegóły dotyczące dostępności architektonicznej budynku znajdują się na stronie Urząd Dostępny pod adresem: <a href="https://www.gunb.gov.pl/strona/urzad-dostepny" target="_blank">https://www.gunb.gov.pl/strona/urzad-dostepny</a></p>
           <br>
           <h2>Informacja o dostępności tłumacza języka migowego za pośrednictwem środków komunikacji elektronicznej</h2>
           <br>
           <p>Zapewniamy pomoc tłumacza języka migowego on-line (za pośrednictwem aplikacji dostępnej z poziomu przeglądarki internetowej w siedzibie Urzędu oraz poza GUNB przez stronę internetową GUNB.</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="button--blue" @click="$emit('close')" data-dismiss="modal">Zamknij</button>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    label: {
      type: String,
      required: true
    },
    labelClass: {
      type: String,
      required: false
    }
  }
}
</script>
<style>
.select-field {
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
padding: 0px;
gap: 10px;
height: 48px;

background: #FFFFFF;
/* Colors/Gray3 */
border: 1px solid #CCCCCC;
border-radius: 4px;
}
.modal .modal-dialog .modal-content {
  padding: 20px 40px 40px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
}

.modal .modal-dialog .modal-header {
  border: 0;
  position: relative;
  max-height: 750px;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  font-weight: bolder;
}

.modal .modal-dialog .modal-body {
  margin-bottom: 25px;
  font-size: 85%;
  text-align: left;
}
.modal-footer {
  display: flex;
  flex-direction: row-reverse;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  text-align: justify;
}
#dostepnosc .tab-content {
  border: 2px solid #eceff2;
  border-radius: 8px;
  padding: 30px;
  margin-top: 30px;
}
.modal .modal-dialog .modal-header h2 {
  font-weight: 700;
  margin-bottom: 25px;
  font-size: 120%;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-primary:hover {
  background-color: #51b6dc;
  border: 1px solid #51b6dc;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn:focus, .btn:hover {
  text-decoration: none;
}
[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}

.btn {
  font-size: 79%;
  text-transform: none;
  border: 1px solid #005ce6;
  background: #005CE6;
  border-radius: 48px;
  padding: 8px 12px;
}

.button--blue {
  /* Button */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 28px;
  gap: 8px;

  width: 194px;
  height: 46px;
  background: #005CE6;
  border-radius: 48px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}
</style>
