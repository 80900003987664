<template>
  <div class="items-start mt-10 flex flex-wrap">
    <div class="w-1/6 faq_left">
      <h2 class="mb-4 lg:mb-10 text-xl tracking-tight leading-loose font-extrabold text-gray-900 sm:text-3xl sm:leading-tight full_text td_cell_left">Baza wiedzy</h2>
      <table class="table_cells" >
        <tr>
          <td class="td_svg"><svg width="127" height="115" viewBox="0 0 127 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M106.652 18.6826C75.5995 0.828518 20.1313 -1.29949 2.10597 37.3759C-15.9193 76.0512 87.1435 131.745 106.652 99.2563C126.161 66.7672 139.774 37.7262 106.652 18.6826Z" fill="#F7F7F7"/>
            <path d="M96.8661 72.1288C96.8661 72.1288 88.6564 34.123 67.9858 34.123C47.3151 34.123 36.3179 93.8698 86.614 100.367L96.8661 72.1288Z" fill="#F4A28C"/>
            <path opacity="0.1" d="M96.8661 72.1288C96.8661 72.1288 88.6564 34.123 67.9858 34.123C47.3151 34.123 36.3179 93.8698 86.614 100.367L96.8661 72.1288Z" fill="black"/>
            <path d="M16.1857 100.037C16.1857 100.037 39.0157 111.839 51.6484 102.972C64.2811 94.1047 95.2255 36.2701 98.1258 30.4431C101.026 24.616 89.6306 -1.62403 82.1363 0.317214C74.6421 2.25845 55.3779 19.9681 58.7975 25.7771C62.6685 31.2897 67.0887 36.48 71.9977 41.2769C71.9977 41.2769 56.3147 76.2199 46.5382 78.9101C36.7617 81.6002 26.54 68.4078 19.9115 73.7001C13.2829 78.9924 4.21745 95.1561 16.1857 100.037Z" fill="#0066FF"/>
            <path d="M80.6798 58.0171C80.6798 58.0171 64.7127 41.9681 57.8336 43.3944C52.1454 44.5721 52.1387 55.701 64.5264 64.9849C67.0431 66.8441 69.1239 69.2137 70.6276 71.9331C72.1313 74.6524 73.0229 77.658 73.2418 80.746C73.5944 85.9277 75.3308 92.0908 80.6798 96.7425C91.9165 106.556 100.259 98.0053 99.1682 84.7958C98.0772 71.5864 97.3586 67.6412 80.6798 58.0171Z" fill="#F4A28C"/>
            <path d="M71.4414 114.712L70.1895 99.3604L100.647 90.2793L105.015 114.611L71.4414 114.712Z" fill="#FFCC33"/>
            <path opacity="0.1" d="M71.234 114.713L70.1895 99.3612L84.7261 95.0234L87.1278 114.713H71.234Z" fill="black"/>
          </svg>
          </td>
        </tr>
        <tr>
          <td class="td_cell_center heading_3-text">Skontaktuj się z nami</td>
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr>
          <td class="td_cell_left">
            <a href="mailto:e-budownictwo@gunb.gov.pl">e-budownictwo@gunb.gov.pl</a></td>
        </tr>
        <tr>
          <td class="td_cell_left">Infolinia: (22) 346 66 64 (pn.-pt. 9:00-15:00)</td>
        </tr>
      </table>
    </div>
    <div class="w-1/8"></div>
    <div ref="faqui" class="w-4/6 faq_list">

      <div v-if="faqs.length > 0" class="basic-list">
        <ul>
          <li v-for="faq in faqs" :key="faq.id" >
            <div class="faq_item" @click="showFaq(faq)">
              <div class="faq_text">
                <svg v-if="faq.open == false" class="faq_arrow" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 12.9405L22.12 11.0605L16 17.1672L9.88 11.0605L8 12.9405L16 20.9405L24 12.9405Z" fill="#0066FF"/>
                </svg>
                <svg v-if="faq.open == true" class="faq_arrow" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 19.0595L9.88 20.9395L16 14.8328L22.12 20.9395L24 19.0595L16 11.0595L8 19.0595Z" fill="#0066FF"/>
                </svg>
                 <div class="faq_text" v-html=faq.question></div>
              </div>
            </div>
            <div v-if="faq.open == true" class="faq_answear">
              <div class="faq_text" v-html=faq.answear></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FaqSection',
  computed: {
    ...mapGetters({
      faqs: 'faqs/mapFaqs'
    })
  },
  methods: {
    showFaq(faq) {
      console.info(faq)
      faq.open = !faq.open
      this.$forceUpdate()
    }
  },
  mounted() {
    const thisInstance = this
    this.$root.$on('openfaq', function() {
      thisInstance.faqs[0].open = false
      thisInstance.showFaq(thisInstance.faqs[0])
      location.href = '#introduction'
      this.$forceUpdate()
    })
  }
}
</script>
<style>

.faq_item {
  box-sizing: border-box;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 908px;
  min-height: 64px;
  background: #FFFFFF;
  border: 1px solid #0066FF;
  border-radius: 16px;
  display: inline-block;
  cursor:pointer;
}

.faq_left {

  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 420px;
  min-height: 1090px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

}
.faq_list {
  display: table;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 908px;
  height: 1090px;
  flex: none;
}

.faq_text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: contents;
  color: #333399;
}

.faq_answear {
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 908px;
  display: inline-block;
  background: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 10px;
}

.faq_arrow {
  display: inline;
}

.heading_3-text {
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #333399;
}
.td_cell_center {
  text-align: center;
  display: contents;
}
.td_svg{
  padding-left: 50px;
  padding-bottom: 10px;
}

.table_cells {
  width: 100%;
}
</style>
