<template>
  <div class="modal-content-cookies">
    <div class="modal-header">
      <h2 class="modal-title">Zarządzanie plikami cookies</h2>
    </div>
    <div class="modal-body">
      <p>
        Wykorzystujemy pliki cookies, aby zapewnić jego prawidłowe funkcjonowanie oraz analizować ruch na stronie.
        Dzięki temu dbamy o wysoką jakość i ciągle udoskonalamy działania naszego serwisu. Możesz zaakceptować wszystkie
        pliki cookies, dostosować swoje ustawienia lub odrzucić te, które nie są niezbędne.
      </p>
      <ul>
        <li>
          <div><p><b>Niezbędne</b> – wymagane do prawidłowego działania serwisu.</p></div><div><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-checkbox"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 11l3 3l8 -8" /><path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" /></svg></div>
        </li>
        <li>
          <div><p><b>Analityczne</b> – pomagają nam analizować ruch na stronie i ulepszać jej działanie.</p></div>
        </li>
      </ul>
      <div class="modal-footer">
        <button type="button" class="button--blue" @click="setAll" data-dismiss="modal">Akceptuj wszystkie</button>
        <button type="button" class="button--white" @click="setEssential" data-dismiss="modal">Akceptuj tylko niezbędne</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: 'cookies',
  props: {},
  methods: {
    setAll () {
      this.$emit('close')
      this.$cookies.set('cookie-accept-status', 'All', '1y')
    },
    setEssential () {
      this.$emit('close')
      this.$cookies.set('cookie-accept-status', 'Essential', '1y')
    }
  }
}
</script>
<style>
.modal-content-cookies {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  outline: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;

  li {
    /* Frame 4389 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    gap: 149px;

    width: 760px;
    height: 48px;
    left: 32px;
    top: 175px;
    margin-top: 10px;

    /* Colors/Gray4 */
    border: 1px solid #E0E0E0;
    border-radius: 8px;
  }
}

.button--white {

box-sizing: border-box;

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 12px 28px;
gap: 8px;

width: 236px;
height: 46px;
border: 1px solid #005CE6;
background: white;
border-radius: 48px;
font-family: 'Roboto', sans-serif;
font-size: 16px;
}

.button--blue {
  /* Button */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 28px;
  gap: 8px;

  width: 194px;
  height: 46px;
  background: #005CE6;
  border-radius: 48px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

</style>
