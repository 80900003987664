<template>
   <div class="head_common">
     <div class="w-1/3" >
     <p class="link_1">
     <svg class="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
       <g clip-path="url(#clip0_13485_17959)">
         <path d="M5.32683 8.66601L13.3335 8.66602L13.3335 7.33268L5.32683 7.33268L5.32683 5.33268L2.66683 7.99935L5.32683 10.666L5.32683 8.66601Z" fill="white"/>
       </g>
       <defs>
         <clipPath id="clip0_13485_17959">
           <rect width="16" height="16" fill="white"/>
         </clipPath>
       </defs>
     </svg>
       <a href="https://e-budownictwo.gunb.gov.pl/" >Portal eBudownictwo</a>
</p>
     </div>
     <div class="w-1/3" >
     <select class="blueselect obywatel" @change="GoToUrl($event)">
       <option value="" selected="selected">e-budownictwo.gunb.gov.pl</option>
       <option value="https://gunb.gov.pl">Przejdź do GUNB</option>
       <option value="https://wnioski.gunb.gov.pl">Przejdź do eBudownictwo Wnioski</option>
       <option value="https://e-dziennikbudowy.gunb.gov.pl">Przejdź do EDB</option>
       <option value="https://c-kob.gunb.gov.pl">Przejdź do cKOB</option>
       <option value="https://zone.gunb.gov.pl/pl">Przejdź do CEEB</option>
       <option value="https://e-crub.gunb.gov.pl">Przejdź do eCRUB</option>
       <option value="https://e-wykonaniazastepcze.gunb.gov.pl">Przejdź do eWykonania Zastępcze</option>
       <option value="https://snrwb.gunb.gov.pl/public/">Przejdź do SNRWB</option>
       <option value="https://wyszukiwarka.gunb.gov.pl">Przejdź do RWDZ</option>
       <option value="https://akademia.gunb.gov.pl">Przejdź do Akademii</option>
     </select>
     </div>
     <div class="w-1/3" >
     <p class="link_1"><svg class="pbody" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
       <g clip-path="url(#clip0_15628_901)">
         <path d="M20.5 6C17.89 6.7 14.83 7 12 7C9.17 7 6.11 6.7 3.5 6L3 8C4.86 8.5 7 8.83 9 9V22H11V16H13V22H15V9C17 8.83 19.14 8.5 21 8L20.5 6ZM12 6C13.1 6 14 5.1 14 4C14 2.9 13.1 2 12 2C10.9 2 10 2.9 10 4C10 5.1 10.9 6 12 6Z" fill="white"/>
       </g>
       <defs>
         <clipPath id="clip0_15628_901">
           <rect width="24" height="24" fill="white"/>
         </clipPath>
       </defs>
     </svg>
         <span class="like_link" @click="showModal()" >Deklaracja dostępności</span>
       </p>
     </div>
     <transition name="pop" appear>
       <div class="modal"
            role="dialog"
            v-if="isModalVisible">
         <modal @close="closeModal" id="dostepnosc" ></modal>
       </div>
       <div class="modal modal__cookies"
            role="dialog"
            style="top: auto; left: auto"
            v-if="isCookiesVisible">
         <cookies @close="closeCookies" id="cookies" ></cookies>
       </div>
       <div class="modal modal__privacy"
            role="dialog"
            v-if="isPrivacyVisible">
         <privacy @close="closePrivacy" id="privacy" ></privacy>
       </div>
     </transition>
   </div>
</template>

<script>
import cookies from './Cookies'
import modal from './Modal'
import privacy from './Privacy'

export default {
  name: 'GUNBHeader',
  components: { modal, cookies, privacy },
  props: {
    msg: String
  },
  data() {
    return {
      isModalVisible: false,
      isCookiesVisible: false,
      isPrivacyVisible: false
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    showCookies() {
      this.isCookiesVisible = true
    },
    showPrivacy() {
      this.isPrivacyVisible = true
    },
    closeCookies() {
      this.isCookiesVisible = false
    },
    closePrivacy() {
      this.isPrivacyVisible = false
    },
    closeModal() {
      this.isModalVisible = false
    },
    GoToUrl(event) {
      window.location = event.target.value
    }
  },
  mounted() {
    const thisInstance = this
    this.$root.$on('showModal', function () {
      thisInstance.showModal()
      thisInstance.$forceUpdate()
    })
    this.$root.$on('showCookies', function () {
      thisInstance.showCookies()
      thisInstance.$forceUpdate()
    })
    this.$root.$on('showPrivacy', function () {
      thisInstance.showPrivacy()
      thisInstance.$forceUpdate()
    })
  }
}
</script>

<style>
.like_link {
  cursor:pointer;
}
.modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: flex;
  outline: 0;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.head_common {
  align-items: center;
  display: -webkit-box;
  padding: 0px 240px;
  gap: 16px;

  width: 100%;
  height: 48px;

  background: #333399;
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}

.modal {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 3rem auto;
  text-align: center;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  background: #FFF;
  z-index: 999;
  transform: none;
}

#dostepnosc .nav-pills {
  background: #eceff2;
  border: 4px solid #eceff2;
  border-radius: 8px
}

#dostepnosc .nav-pills .nav-link {
  background: #eceff2;
  color: #212529
}

#dostepnosc .nav-pills .nav-link.active {
  background: #fff;
  color: #005ce6
}

#dostepnosc .tab-content {
  border: 2px solid #eceff2;
  border-radius: 8px;
  padding: 30px;
  margin-top: 30px
}

#dostepnosc #udost-skroty td,#dostepnosc #udost-skroty th {
  padding-bottom: 1em
}

#dostepnosc #udost-skroty td {
  padding-left: 2em
}

#dostepnosc .d-flex strong {
  position: relative;
  top: -.2em
}

#dostepnosc .d-flex label {
  cursor: pointer
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
}

.link_1 {
  display: -webkit-box;
  padding-top: 10px;
  color: white;
}
.arrow {
  margin: 5px;
}

.pbody {
  margin-right: 5px;
}

.blueselect {
  color: #333399;
  border-color: #FFF;
  background: white;
  border-radius: 8px;
  padding: 6px;
  cursor: pointer;
  margin-top: 8px;
}
.fade.show {
  opacity: 1;
}
.modal {
  z-index: 10000;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
}

.modal, .modal-open {
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  margin: 0px 100px;
}
.fade {
  opacity: 0;
  transition: opacity .15s linear;
}
.modal__cookies {
  background: #fff;
  padding: 20px;
  width: 824px;
  height: 381px;
  border-radius: 16px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

</style>
